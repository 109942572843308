import { Spin } from "antd";
import {
  CautionContainer,
  CautionFee,
  CautionFeeItem,
  CautionLoading,
} from "./styled";
import { convertFromWei } from "../../../utils/convertNumber";
import { useEffect } from "react";

const CautionBlock = ({
  confirmRouter,
  loadingConfirmRouter,
  setCaution,
  selectedWallets,
}: any) => {
  const handleChangeReason = (reason: any) => {
    switch (reason) {
      case "FEE_AND_INPUT_ASSET":
        return "for swap and network fee";
      case "FEE":
        return "for network fee";
      case "INPUT_ASSET":
        return "for input asset";
      default:
        return "";
    }
  };

  const filteredValidationStatus = confirmRouter?.validationStatus
    ?.map((validation: any) => ({
      ...validation,
      wallets: validation.wallets.map((wallet: any) => ({
        ...wallet,
        requiredAssets: wallet.requiredAssets.filter(
          (asset: any) => asset.ok === false
        ),
      })),
    }))
    .filter((validation: any) =>
      validation.wallets.some((wallet: any) => wallet.requiredAssets.length > 0)
    );

  useEffect(() => {
    if (filteredValidationStatus && filteredValidationStatus.length > 0) {
      setCaution(true);
    } else {
      setCaution(false);
    }
  }, [filteredValidationStatus]);

  const emptyKeys =
    selectedWallets &&
    Object.keys(selectedWallets).filter((key) => selectedWallets[key] === "");

  return (
    <CautionContainer>
      {loadingConfirmRouter ? (
        <CautionLoading>
          <Spin size="large" />
        </CautionLoading>
      ) : (
        <>
          <CautionFee>
            {filteredValidationStatus?.length > 0 ||
            (emptyKeys && emptyKeys.length > 0) ? (
              <p>Caution:</p>
            ) : (
              <></>
            )}
            <div>
              {emptyKeys &&
                emptyKeys.length > 0 &&
                emptyKeys.map((item: any, index: any) => {
                  return (
                    <CautionFeeItem key={index}>
                      + Please connect wallet {item}
                    </CautionFeeItem>
                  );
                })}
              {filteredValidationStatus?.length > 0 && (
                <>
                  {filteredValidationStatus.map((item: any, index: any) => (
                    <CautionFeeItem key={index}>
                      + Need{" "}
                      <span>
                        {convertFromWei(
                          item.wallets[0].requiredAssets[0].requiredAmount
                            .amount,
                          item.wallets[0].requiredAssets[0].requiredAmount
                            .decimals
                        )}{" "}
                        {item.wallets[0].requiredAssets[0].asset.symbol}
                      </span>{" "}
                      {handleChangeReason(
                        item.wallets[0].requiredAssets[0].reason
                      )}
                      , but you have{" "}
                      <span>
                        {convertFromWei(
                          item.wallets[0].requiredAssets[0].currentAmount
                            .amount,
                          item.wallets[0].requiredAssets[0].currentAmount
                            .decimals
                        )}{" "}
                        {item.wallets[0].requiredAssets[0].asset.symbol}
                      </span>{" "}
                      in your <span>{item.blockchain}</span> wallet
                    </CautionFeeItem>
                  ))}
                </>
              )}
            </div>
          </CautionFee>
        </>
      )}
    </CautionContainer>
  );
};

export default CautionBlock;
