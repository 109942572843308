import { useEffect, useState } from "react";
import { useCosmosAccount, useCosmosWallets } from "@cosmostation/use-wallets";
import { ButtonWallet, WalletConnectedTag } from "../styled";
import { useDispatch, useSelector } from "react-redux";
import {
  addConnectedWallet,
  removeConnectedWallet,
} from "../../../../store/features/accountSlice";
import {
  registerCosmosWallet,
  CosmosRegisterWallet,
} from "@cosmostation/wallets";
import { addWallets } from "../../../../store/features/walletCosmosSlice";

interface CosmosInterface {
  searchText: any;
  setLocalConnectedWallets: any;
  localConnectedWallets: any;
}
const ConsmosWalletList = ({
  searchText,
  setLocalConnectedWallets,
  localConnectedWallets,
}: CosmosInterface) => {
  const dispatch = useDispatch();
  const { cosmosWallets, selectWallet, currentWallet } = useCosmosWallets();
  const { data } = useCosmosAccount("osmosis-1");
  const [checkConnectWallet, setCheckConnectWallet] = useState("leap");
  const [osmosisAddress, setOsmosisAddress] = useState<string | null>(null);
  const [cosmoshubAddress, setCosmoshubAddress] = useState<string | null>(null);
  const connectedWallets = useSelector(
    (state: any) => state.accountSlice.referrals
  );

  // Function to get the address of the string
  const getChainAddress = async (chainId: string) => {
    try {
      const wallet =
        checkConnectWallet === "keplr" ? window?.keplr : window?.leap;
      await wallet?.enable(chainId);
      const key = await wallet?.getKey(chainId);
      return key?.bech32Address;
    } catch (error) {
      console.error(`Error fetching address for ${chainId}:`, error);
      return null;
    }
  };

  // Get the address for both strings
  useEffect(() => {
    const fetchAddresses = async () => {
      const osmosisAddr = await getChainAddress("osmosis-1");
      const cosmoshubAddr = await getChainAddress("cosmoshub-4");
      setOsmosisAddress(osmosisAddr);
      setCosmoshubAddress(cosmoshubAddr);
    };

    fetchAddresses();
  }, [checkConnectWallet]);

  // Handle connection
  const handleConnect = async (wallet: any) => {
    setCheckConnectWallet(wallet.name.toLowerCase());
    try {
      await selectWallet(wallet.id);
    } catch (error) {
      console.error("Error connecting wallet:", error);
    }
  };

  // Handle disconnection
  const handleDisconnect = (wallet: any) => {
    const walletAddress = localConnectedWallets[wallet.name]?.address;
    if (!walletAddress) {
      console.error("Wallet address not found for:", wallet.name);
      return;
    }
    data?.methods?.disconnect();
    dispatch(removeConnectedWallet(walletAddress));
    setLocalConnectedWallets((prev: any) => {
      const { [wallet.name]: _, ...remainingWallets } = prev;
      return remainingWallets;
    });
  };

  const getOsmosisAddress = async () => {
    try {
      const wallet =
        checkConnectWallet === "keplr" ? window?.keplr : window?.leap;
      const chainId = "osmosis-1";
      await wallet?.enable(chainId);
      const key = await wallet?.getKey(chainId);
      return key?.bech32Address;
    } catch (error) {
      console.error("Error fetching Osmosis address:", error);
      return null;
    }
  };

  // useEffect(() => {
  //   const fetchOsmosisAddress = async () => {
  //     try {
  //       const address = await getOsmosisAddress();
  //       // console.log("Fetched Osmosis address:", address);
  //     } catch (error) {
  //       console.error("Failed to fetch Osmosis address:", error);
  //     }
  //   };

  //   fetchOsmosisAddress();
  // }, [data]);

  useEffect(() => {
    const fetchAddress = async () => {
      const address = await getOsmosisAddress();
      setOsmosisAddress(address);
    };
    fetchAddress();
  }, []);

  // Add connected wallet to Redux after address is fetched
  useEffect(() => {
    const updateWalletWithOsmosis = async () => {
      if (currentWallet && data?.account?.address) {
        const osmosisAddress = await getOsmosisAddress();

        const walletData = {
          img: `https://raw.githubusercontent.com/rango-exchange/assets/main/wallets/${
            currentWallet.name.toLowerCase()?.split(" ")[0] === "leap"
              ? "leap-cosmos"
              : currentWallet.name.toLowerCase()?.split(" ")[0]
          }/icon.svg`,
          name: currentWallet?.name,
          address: cosmoshubAddress,
          id: currentWallet?.id,
          type: "COSMOS",
          public_key: data.account.public_key,
          osmosisAddress: osmosisAddress || null,
        };

        setLocalConnectedWallets((prev: any) => ({
          ...prev,
          [currentWallet?.name]: walletData,
        }));
        dispatch(addConnectedWallet(walletData));
      }
    };

    if (cosmoshubAddress && osmosisAddress) {
      updateWalletWithOsmosis();
    }
  }, [osmosisAddress, dispatch, cosmoshubAddress]);

  // Filter wallets by search text
  const filteredWallets = cosmosWallets.filter(
    (wallet: any) =>
      !searchText ||
      wallet.name.toLowerCase().includes(searchText.toLowerCase())
  );

  useEffect(() => {
    const wallet: CosmosRegisterWallet = {
      name: "Keplr",
      logo: "https://wallet.keplr.app/keplr-brand-assets/keplr-logo.svg",
      events: {
        on(type, listener) {
          if (type === "AccountChanged") {
            window.addEventListener("keplr_keystorechange", listener);
          }
        },
        off(type, listener) {
          if (type === "AccountChanged") {
            window.removeEventListener("keplr_keystorechange", listener);
          }
        },
      },
      methods: {
        getSupportedChainIds: async () => {
          return ["cosmoshub-4"];
        },
        connect: async (chainIds) => {
          const cIds = typeof chainIds === "string" ? [chainIds] : chainIds;
          const supportedChainIds = await wallet.methods.getSupportedChainIds();

          if (!cIds.every((cId) => supportedChainIds.includes(cId))) {
            throw new Error("Unsupported chainId is exist");
          }
          await window.keplr.enable(chainIds);
        },
        getAccount: async (chainId) => {
          const response = await window.keplr.getKey(chainId);
          return {
            address: response.bech32Address,
            name: response.name,
            public_key: {
              type: response.algo,
              value: Buffer.from(response.pubKey).toString("base64"),
            },
            is_ledger: response.isNanoLedger,
          };
        },
        signAmino: async (chainId, document, options) => {
          if (typeof options?.edit_mode?.fee === "boolean") {
            window.keplr.defaultOptions.sign.preferNoSetFee =
              options.edit_mode.fee;
          }
          if (typeof options?.edit_mode?.memo === "boolean") {
            window.keplr.defaultOptions.sign.preferNoSetMemo =
              options.edit_mode.memo;
          }
          if (typeof options?.is_check_balance === "boolean") {
            window.keplr.defaultOptions.sign.disableBalanceCheck =
              options.is_check_balance;
          }
          const signer =
            options?.signer ||
            (await wallet.methods.getAccount(chainId)).address;
          const response = await window.keplr.signAmino(
            chainId,
            signer,
            document
          );
          return {
            signature: response.signature.signature,
            signed_doc: response.signed,
          };
        },
        signDirect: async (chainId, document, options) => {
          if (typeof options?.edit_mode?.fee === "boolean") {
            window.keplr.defaultOptions.sign.preferNoSetFee =
              options.edit_mode.fee;
          }
          if (typeof options?.edit_mode?.memo === "boolean") {
            window.keplr.defaultOptions.sign.preferNoSetMemo =
              options.edit_mode.memo;
          }
          if (typeof options?.is_check_balance === "boolean") {
            window.keplr.defaultOptions.sign.disableBalanceCheck =
              !options.is_check_balance;
          }
          const account = await wallet.methods.getAccount(chainId);
          if (account.is_ledger) {
            throw new Error("Ledger is not supported");
          }
          const signer = options?.signer || account.address;
          const signingDoc = {
            accountNumber: document.account_number,
            authInfoBytes: document.auth_info_bytes,
            chainId: document.chain_id,
            bodyBytes: document.body_bytes,
          };
          const response = await window.keplr.signDirect(
            chainId,
            signer,
            signingDoc
          );
          return {
            signature: response.signature.signature,
            signed_doc: {
              auth_info_bytes: response.signed.authInfoBytes,
              body_bytes: response.signed.bodyBytes,
            },
          };
        },
        sendTransaction: async (chainId, tx_bytes, mode) => {
          const broadcastMode =
            mode === 1
              ? "block"
              : mode === 2
              ? "sync"
              : mode === 3
              ? "async"
              : "sync";

          const txBytes =
            typeof tx_bytes === "string"
              ? new Uint8Array(Buffer.from(tx_bytes, "base64"))
              : tx_bytes;
          const response = await window.keplr.sendTx(
            chainId,
            txBytes,
            broadcastMode
          );
          const txHash = Buffer.from(response).toString("hex").toUpperCase();
          return txHash;
        },
        addChain: async (chain) => {
          const coinType = chain.coin_type
            ? Number(chain.coin_type.replaceAll("'", ""))
            : 118;
          await window.keplr.experimentalSuggestChain({
            chainId: chain.chain_id,
            chainName: chain.chain_name,
            rpc: chain.lcd_url,
            rest: chain.lcd_url,
            bip44: {
              coinType,
            },
            bech32Config: {
              bech32PrefixAccAddr: chain.address_prefix,
              bech32PrefixAccPub: chain.address_prefix + "pub",
              bech32PrefixValAddr: chain.address_prefix + "valoper",
              bech32PrefixValPub: chain.address_prefix + "valoperpub",
              bech32PrefixConsAddr: chain.address_prefix + "valcons",
              bech32PrefixConsPub: chain.address_prefix + "valconspub",
            },
            currencies: [
              {
                coinDenom: chain.display_denom,
                coinMinimalDenom: chain.base_denom,
                coinDecimals: chain.decimals || 6,
                coinGeckoId: chain.coingecko_id || "unknown",
              },
            ],
            feeCurrencies: [
              {
                coinDenom: chain.display_denom,
                coinMinimalDenom: chain.base_denom,
                coinDecimals: chain.decimals || 6,
                coinGeckoId: chain.coingecko_id || "unknown",
                gasPriceStep: {
                  low: chain?.gas_rate?.tiny
                    ? Number(chain?.gas_rate?.tiny)
                    : 0.01,
                  average: chain?.gas_rate?.low
                    ? Number(chain?.gas_rate?.low)
                    : 0.025,
                  high: chain?.gas_rate?.average
                    ? Number(chain?.gas_rate?.average)
                    : 0.04,
                },
              },
            ],
            stakeCurrency: {
              coinDenom: chain.display_denom,
              coinMinimalDenom: chain.base_denom,
              coinDecimals: chain.decimals || 6,
              coinGeckoId: chain.coingecko_id || "unknown",
            },
          });
        },
      },
    };
    setTimeout(() => {
      registerCosmosWallet(wallet);
    }, 1500);
  }, []);

  // -----------------------------------
  // register leap
  useEffect(() => {
    const wallet: CosmosRegisterWallet = {
      name: "Leap",
      logo: "https://miro.medium.com/v2/resize:fill:176:176/1*2jNLyjIPuU8HBbayPapwcQ.png",
      events: {
        on(type, listener) {
          if (type === "AccountChanged") {
            window.addEventListener("leap_keystorechange", listener);
          }
        },
        off(type, listener) {
          if (type === "AccountChanged") {
            window.removeEventListener("leap_keystorechange", listener);
          }
        },
      },
      methods: {
        getSupportedChainIds: async () => {
          return ["cosmoshub-4"];
        },
        connect: async (chainIds) => {
          const cIds = typeof chainIds === "string" ? [chainIds] : chainIds;
          const supportedChainIds = await wallet.methods.getSupportedChainIds();

          if (!cIds.every((cId) => supportedChainIds.includes(cId))) {
            throw new Error("Unsupported chainId is exist");
          }

          await window.leap.enable(chainIds);
        },
        getAccount: async (chainId) => {
          const response = await window.leap.getKey(chainId);

          return {
            address: response.bech32Address,
            name: response.name,
            public_key: {
              type: response.algo,
              value: Buffer.from(response.pubKey).toString("base64"),
            },
            is_ledger: response.isNanoLedger,
          };
        },
        signAmino: async (chainId, document, options) => {
          if (typeof options?.edit_mode?.fee === "boolean") {
            window.leap.defaultOptions.sign.preferNoSetFee =
              options.edit_mode.fee;
          }

          if (typeof options?.edit_mode?.memo === "boolean") {
            window.leap.defaultOptions.sign.preferNoSetMemo =
              options.edit_mode.memo;
          }

          if (typeof options?.is_check_balance === "boolean") {
            window.leap.defaultOptions.sign.disableBalanceCheck =
              options.is_check_balance;
          }

          const signer =
            options?.signer ||
            (await wallet.methods.getAccount(chainId)).address;

          const response = await window.leap.signAmino(
            chainId,
            signer,
            document
          );

          return {
            signature: response.signature.signature,
            signed_doc: response.signed,
          };
        },
        signDirect: async (chainId, document, options) => {
          if (typeof options?.edit_mode?.fee === "boolean") {
            window.leap.defaultOptions.sign.preferNoSetFee =
              options.edit_mode.fee;
          }

          if (typeof options?.edit_mode?.memo === "boolean") {
            window.leap.defaultOptions.sign.preferNoSetMemo =
              options.edit_mode.memo;
          }

          if (typeof options?.is_check_balance === "boolean") {
            window.leap.defaultOptions.sign.disableBalanceCheck =
              !options.is_check_balance;
          }

          const account = await wallet.methods.getAccount(chainId);

          if (account.is_ledger) {
            throw new Error("Ledger is not supported");
          }

          const signer = options?.signer || account.address;

          const signingDoc = {
            accountNumber: document.account_number,
            authInfoBytes: document.auth_info_bytes,
            chainId: document.chain_id,
            bodyBytes: document.body_bytes,
          };

          const response = await window.leap.signDirect(
            chainId,
            signer,
            signingDoc
          );

          return {
            signature: response.signature.signature,
            signed_doc: {
              auth_info_bytes: response.signed.authInfoBytes,
              body_bytes: response.signed.bodyBytes,
            },
          };
        },
        sendTransaction: async (chainId, tx_bytes, mode) => {
          const broadcastMode =
            mode === 1
              ? "block"
              : mode === 2
              ? "sync"
              : mode === 3
              ? "async"
              : "sync";

          const txBytes =
            typeof tx_bytes === "string"
              ? new Uint8Array(Buffer.from(tx_bytes, "base64"))
              : tx_bytes;

          const response = await window.leap.sendTx(
            chainId,
            txBytes,
            broadcastMode
          );

          const txHash = Buffer.from(response).toString("hex").toUpperCase();

          return txHash;
        },
        addChain: async (chain) => {
          const coinType = chain.coin_type
            ? Number(chain.coin_type.replaceAll("'", ""))
            : 118;

          await window.leap.experimentalSuggestChain({
            chainId: chain.chain_id,
            chainName: chain.chain_name,
            rpc: chain.lcd_url,
            rest: chain.lcd_url,
            bip44: {
              coinType,
            },
            bech32Config: {
              bech32PrefixAccAddr: chain.address_prefix,
              bech32PrefixAccPub: chain.address_prefix + "pub",
              bech32PrefixValAddr: chain.address_prefix + "valoper",
              bech32PrefixValPub: chain.address_prefix + "valoperpub",
              bech32PrefixConsAddr: chain.address_prefix + "valcons",
              bech32PrefixConsPub: chain.address_prefix + "valconspub",
            },
            currencies: [
              {
                coinDenom: chain.display_denom,
                coinMinimalDenom: chain.base_denom,
                coinDecimals: chain.decimals || 6,
                coinGeckoId: chain.coingecko_id || "unknown",
              },
            ],
            feeCurrencies: [
              {
                coinDenom: chain.display_denom,
                coinMinimalDenom: chain.base_denom,
                coinDecimals: chain.decimals || 6,
                coinGeckoId: chain.coingecko_id || "unknown",
                gasPriceStep: {
                  low: chain?.gas_rate?.tiny
                    ? Number(chain?.gas_rate?.tiny)
                    : 0.01,
                  average: chain?.gas_rate?.low
                    ? Number(chain?.gas_rate?.low)
                    : 0.025,
                  high: chain?.gas_rate?.average
                    ? Number(chain?.gas_rate?.average)
                    : 0.04,
                },
              },
            ],
            stakeCurrency: {
              coinDenom: chain.display_denom,
              coinMinimalDenom: chain.base_denom,
              coinDecimals: chain.decimals || 6,
              coinGeckoId: chain.coingecko_id || "unknown",
            },
          });
        },
      },
    };
    setTimeout(() => {
      registerCosmosWallet(wallet);
    }, 1500);
  }, []);

  //
  const getAllAddressesInSingleArray = async (chainIds: string[]) => {
    const allAddresses: string[] = [];

    for (const chainId of chainIds) {
      try {
        await window.keplr.enable(chainId);
        const offlineSigner = window.keplr.getOfflineSigner(chainId);
        const accounts = await offlineSigner.getAccounts();
        accounts.forEach((account: any) => {
          if (!allAddresses.includes(account.address)) {
            allAddresses.push(account.address);
          }
        });
      } catch (error) {
        console.error(`Error fetching addresses for ${chainId}:`, error);
      }
    }
    dispatch(addWallets(allAddresses));
    return allAddresses;
  };
  (async () => {
    const chainIds = ["cosmoshub-4", "osmosis-1", "juno-1", "stargaze-1"];
    getAllAddressesInSingleArray(chainIds);
  })();

  return (
    <>
      {filteredWallets.length > 0 ? (
        filteredWallets.map((wallet) => {
          const isConnected: any = !!localConnectedWallets[wallet.name];
          return (
            <ButtonWallet
              onClick={() => {
                if (isConnected) {
                  handleDisconnect(wallet);
                } else {
                  handleConnect(wallet);
                }
              }}
              key={wallet.id}
            >
              <figure>
                <img
                  src={`https://raw.githubusercontent.com/rango-exchange/assets/main/wallets/${
                    wallet.name.toLowerCase()?.split(" ")[0] === "leap"
                      ? "leap-cosmos"
                      : wallet.name.toLowerCase()?.split(" ")[0]
                  }/icon.svg`}
                  alt="icon"
                />
              </figure>
              <p>{wallet.name.split(" ")[0]}</p>
              <WalletConnectedTag
                className={isConnected ? "connected" : "normal"}
              >
                {isConnected ? "Connected" : "Not connect"}
              </WalletConnectedTag>
            </ButtonWallet>
          );
        })
      ) : (
        <></>
      )}
    </>
  );
};

export default ConsmosWalletList;
