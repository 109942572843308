import { createSlice } from "@reduxjs/toolkit";

export interface walletCosmosState {
  walletCosmos: string[];
}

const initialState: walletCosmosState = {
  walletCosmos: [],
};

export const walletCosmosSlice = createSlice({
  name: "walletCosmosSlice",
  initialState,
  reducers: {
    addWallets(state, action) {
      state.walletCosmos = Array.from(
        new Set([...state.walletCosmos, ...action.payload])
      );
    },
    removeWallet(state, action) {
      state.walletCosmos = state.walletCosmos.filter(
        (address) => address !== action.payload
      );
    },
  },
});

export const { addWallets, removeWallet } = walletCosmosSlice.actions;
export default walletCosmosSlice.reducer;
