import { useContext, useState } from "react";
import {
  ChooseTokenSearch,
  ChooseTokenWrapper,
} from "../../../../page/Swap/ChooseToken/styled";
import WalletConnectBlock from "../../../Common/WalletButton";
import { ContextProviderWrapper } from "../../../Context";
import { MultiChainContainer, MultiChainFilter } from "./styled";
import { Controller, useForm } from "react-hook-form";
import search_icon from "../../../../assets/Dashboard/Common/search_icon.svg";
import InputCommon from "../../../Common/Input";
import clear from "../../../../assets/Dashboard/Common/clear.svg";

type FormValuesProps = {
  search: string;
};

const ModalConnectMultiChain = () => {
  const [selectFilterType, setSelectFilterType] = useState({
    id: 0,
    value: "All",
  });
  const { theme } = useContext(ContextProviderWrapper)!;
  const defaultValues = {
    search: "",
  };

  const methods = useForm<FormValuesProps>({
    defaultValues,
    mode: "onChange",
  });
  const { control, watch } = methods;
  const searchText = watch("search");
  const valueFilter = [
    {
      id: 0,
      value: "All",
    },
    {
      id: 1,
      value: "Popular",
    },
    {
      id: 2,
      value: "EVM",
    },
    {
      id: 3,
      value: "Non EVM",
    },
    {
      id: 4,
      value: "Cosmos",
    },
    {
      id: 5,
      value: "Tron",
    },
  ];

  return (
    <MultiChainContainer>
      <MultiChainFilter>
        {valueFilter.map((item, index) => {
          return (
            <li
              className={selectFilterType.id === item.id ? "active" : ""}
              key={index}
              onClick={() => {
                setSelectFilterType(item);
              }}
            >
              <p>{item.value}</p>
            </li>
          );
        })}
      </MultiChainFilter>
      <ChooseTokenWrapper className={theme}>
        <ChooseTokenSearch className={theme}>
          <Controller
            name="search"
            control={control}
            render={({ field }: any) => (
              <InputCommon
                {...field}
                // disabled={loadingState ? true : false}
                placeHolder="Enter wallet name"
                name="search"
                allowClear={{ clearIcon: <img src={clear} alt="icon" /> }}
                prefix={<img src={search_icon} alt="icon" />}
              />
            )}
          />
          <p>
            <span>Search wallet</span>
          </p>
        </ChooseTokenSearch>
      </ChooseTokenWrapper>
      {/* Wallet list */}
      <WalletConnectBlock
        searchText={searchText}
        selectFilterType={selectFilterType}
      />
      {/* ----------- */}
    </MultiChainContainer>
  );
};

export default ModalConnectMultiChain;
