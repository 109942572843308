import {
  ButtonWallet,
  WalletConnectedTag,
} from "../../../../../../components/Common/WalletButton/styled";
import { shortenAddress } from "../../../../../../utils/addressUser";

const PickWalletItem = ({
  item,
  wallet,
  updateSelectedWalletAddress,
  lastItem,
  handleSwitch,
  setReceiveWallet,
  blockchain,
}: any) => {
  const handleClickWallet = async () => {
    try {
      await updateSelectedWalletAddress(
        item.blockchain,
        wallet.osmosisAddress?.length > 0 && blockchain === "OSMOSIS"
          ? wallet.osmosisAddress
          : wallet.address
      );
      if (lastItem) {
        handleSwitch(false);
        setReceiveWallet(
          wallet.osmosisAddress?.length > 0 && blockchain === "OSMOSIS"
            ? wallet.osmosisAddress
            : wallet.address
        );
      }
    } catch (error) {
      console.error("Error updating wallet:", error);
    }
  };

  return (
    <ButtonWallet
      onClick={() => {
        handleClickWallet();
      }}
      className={
        wallet.osmosisAddress?.length > 0 && blockchain === "OSMOSIS"
          ? "active"
          : wallet.address === item.address
          ? "active"
          : ""
      }
    >
      <figure>
        <img src={wallet.img} alt="icon" loading="lazy" />
      </figure>
      <p>{wallet.name}</p>
      <WalletConnectedTag className="connected">
        {shortenAddress(
          wallet.osmosisAddress?.length > 0 && blockchain === "OSMOSIS"
            ? wallet.osmosisAddress
            : wallet.address
        )}
      </WalletConnectedTag>
    </ButtonWallet>
  );
};

export default PickWalletItem;
