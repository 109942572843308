import { useEffect, useMemo, useState } from "react";
import { ReceiveWalletContainer, ReceiveWallet } from "./styled";
import { ButtonCommon } from "../../../../Layout/styled";
import { Checkbox, Switch } from "antd";
import InputCommon from "../../../../components/Common/Input";
import { Controller, useForm } from "react-hook-form";
import { SwapInputValue } from "../../../Swap/styled";
import { useSelector } from "react-redux";
import PickWallet from "./Pick";
import { useDebounceTyping } from "../../../../hooks/useDebounce";
import { KIBBLE_API } from "../../../../services/api";
import { useDispatch } from "../../../../store/store";
import { updateMarkedResult } from "../../../../store/features/accountSlice";

interface walletInterface {
  setReceiveWallet: any;
  setOpenModalReceipent: any;
  receiveWallet: string;
  nameChainTo: any;
  swapsRouter: any;
  selectedWallets: any;
  updateSelectedWalletAddress: any;
  confirmRouter: any;
}

const ModalWallet = ({
  setReceiveWallet,
  setOpenModalReceipent,
  receiveWallet,
  nameChainTo,
  swapsRouter,
  selectedWallets,
  updateSelectedWalletAddress,
  confirmRouter,
}: walletInterface) => {
  const KIB_API_RANGO: any = process.env.REACT_APP_KIBBLE_RANGO;
  const [isSelect, setIsSelect] = useState(true);
  const [switchState, setSwitchState] = useState(false);

  const connectedWallets = useSelector(
    (state: any) => state.accountSlice.referrals
  );

  const [shouldAutoUpdate, setShouldAutoUpdate] = useState(true);

  const handleCheckSelect = (e: any) => {
    setIsSelect(e.target.checked);
  };
  const handleSwitch = (value: any) => {
    setSwitchState(value);
  };

  const { control } = useForm({ mode: "onChange" });
  const onChangeInputFrom = (e: any) => {
    setReceiveWallet(e.target.value);
    if (e.target.value.length < 1) {
      handleManualUpdate(markedResult[markedResult.length - 1]?.blockchain, "");
    }
  };

  const debounceWallet = useDebounceTyping(receiveWallet, 1000);
  useEffect(() => {
    if (debounceWallet.length > 0) {
      handleManualUpdate(
        markedResult[markedResult.length - 1]?.blockchain,
        debounceWallet
      );
    }
  }, [debounceWallet]);

  const dataChainStore = useSelector(
    (state: any) => state.accountSlice.dataChain
  );

  const getSwapsFromSelectedWallets = (
    selectedWallets: Record<string, string> | undefined,
    swapsRouter: any[]
  ) => {
    if (!selectedWallets || typeof selectedWallets !== "object") return [];

    return Object.entries(selectedWallets).map(([blockchain, address]) => {
      const swapInfo = swapsRouter?.find(
        (swap: any) =>
          swap.from.blockchain.toUpperCase() === blockchain.toUpperCase() ||
          swap.to.blockchain.toUpperCase() === blockchain.toUpperCase()
      );

      const displayAddress =
        blockchain.toUpperCase() === "OSMOSIS"
          ? connectedWallets.find((wallet: any) => wallet.osmosisAddress)
              ?.osmosisAddress || address
          : address;

      return {
        blockchain,
        address: displayAddress || "",
        logo:
          swapInfo?.from.blockchain.toUpperCase() === blockchain.toUpperCase()
            ? swapInfo?.from.blockchainLogo
            : swapInfo?.to.blockchainLogo,
        name:
          swapInfo?.from.blockchain.toUpperCase() === blockchain.toUpperCase()
            ? swapInfo?.from.blockchain
            : swapInfo?.to.blockchain,
        token:
          swapInfo?.from.blockchain.toUpperCase() === blockchain.toUpperCase()
            ? swapInfo?.from.symbol
            : swapInfo?.to.symbol,
        tokenAddress:
          swapInfo?.from.blockchain.toUpperCase() === blockchain.toUpperCase()
            ? swapInfo?.from.address
            : swapInfo?.to.address,
      };
    });
  };

  const result = getSwapsFromSelectedWallets(selectedWallets, swapsRouter);

  const markResultWithType = (result: any[], dataChainStore: any[]) => {
    return result.map((item) => {
      const chainData = dataChainStore.find(
        (chain: any) =>
          chain.name.toUpperCase() === item.blockchain.toUpperCase()
      );
      return {
        ...item,
        type: chainData
          ? chainData.type === "SOLANA"
            ? "Solana"
            : chainData.type
          : "UNKNOWN",
      };
    });
  };

  const markedResult: any = useMemo(
    () => markResultWithType(result, dataChainStore),
    [result, dataChainStore]
  );

  const handleAutoUpdate = () => {
    const updatedWallets: Record<string, string> = {};

    markedResult.forEach((markedItem: any) => {
      const matchingWallet = connectedWallets.find((wallet: any) => {
        if (markedItem.blockchain.toUpperCase() === "OSMOSIS") {
          return wallet.osmosisAddress;
        }
        return wallet.type.toUpperCase() === markedItem.type.toUpperCase();
      });

      const walletAddress =
        markedItem.blockchain.toUpperCase() === "OSMOSIS"
          ? matchingWallet?.osmosisAddress
          : matchingWallet?.address;

      if (
        walletAddress &&
        walletAddress !== selectedWallets[markedItem.blockchain]
      ) {
        updatedWallets[markedItem.blockchain] = walletAddress;
      }
    });

    if (Object.keys(updatedWallets).length > 0) {
      Object.entries(updatedWallets).forEach(([blockchain, address]) => {
        updateSelectedWalletAddress(blockchain, address);
      });
    }
  };

  useEffect(() => {
    if (shouldAutoUpdate) {
      handleAutoUpdate();
    }
  }, [markedResult, shouldAutoUpdate, confirmRouter]);

  const handleManualUpdate = (blockchain: string, newAddress: string) => {
    setShouldAutoUpdate(false);
    updateSelectedWalletAddress(blockchain, newAddress);
  };

  useEffect(() => {
    if (switchState) {
      handleManualUpdate(markedResult[markedResult.length - 1]?.blockchain, "");
    }
  }, [switchState]);

  const [balances, setBalances] = useState<any[]>([]);
  const seenAddresses = useMemo(() => new Set(), []);
  const fetchWalletDetails = async (address: string, blockchain: string) => {
    try {
      const response: any = await KIBBLE_API.getMultichainBalance(
        blockchain,
        address,
        KIB_API_RANGO
      );

      if (response && response.data.wallets) {
        const walletData = {
          address,
          blockchain,
          balances: response.data.wallets[0].balances,
        };

        setBalances((prev) => {
          const exists = prev.some(
            (item) =>
              item.address === walletData.address &&
              item.blockchain === walletData.blockchain
          );

          return exists ? prev : [...prev, walletData];
        });
      }
    } catch (err) {
      console.error("Error fetching wallet details:", err);
    }
  };

  const checkBalances = async () => {
    for (const wallet of markedResult) {
      const { address, blockchain } = wallet;
      const key = `${address}_${blockchain}`;

      if (!seenAddresses.has(key)) {
        seenAddresses.add(key);
        if (address) {
          await fetchWalletDetails(address, blockchain);
        }
      }
    }
  };

  const updatedMarkedResult = useMemo(() => {
    return markedResult.map((item: any) => {
      const walletBalances = balances.find(
        (balanceItem) =>
          balanceItem.address === item.address &&
          balanceItem.blockchain === item.blockchain
      );

      const nativeBalance = walletBalances?.balances.find(
        (balance: any) => balance.asset.address === null
      );
      const tokenBalance = walletBalances?.balances.find(
        (balance: any) =>
          balance.asset.address &&
          balance.asset.address.toLowerCase() ===
            item.tokenAddress?.toLowerCase()
      );

      return {
        ...item,
        balanceNative: nativeBalance
          ? nativeBalance.amount.amount /
            Math.pow(10, nativeBalance.amount.decimals)
          : 0,
        balanceToken: tokenBalance
          ? tokenBalance.amount.amount /
            Math.pow(10, tokenBalance.amount.decimals)
          : 0,
      };
    });
  }, [markedResult, balances]);

  useEffect(() => {
    if (markedResult.length > 0) {
      checkBalances();
    }
  }, [markedResult]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (updatedMarkedResult.length > 0) {
      dispatch(updateMarkedResult(updatedMarkedResult));
    }
  }, [updatedMarkedResult, dispatch]);

  return (
    <ReceiveWalletContainer>
      <div className="container-receiveWallet">
        {updatedMarkedResult?.map((item: any, index: any) => {
          return (
            <PickWallet
              key={index}
              item={item}
              nameChainTo={nameChainTo}
              updateSelectedWalletAddress={handleManualUpdate}
              lastItem={index === markedResult?.length - 1}
              handleSwitch={handleSwitch}
              setReceiveWallet={setReceiveWallet}
            />
          );
        })}
      </div>
      <ReceiveWallet>
        <div className="heading-wallet">
          <h3 className="title-wallet">Recipient Wallet</h3>
          <Switch checked={switchState} onChange={handleSwitch} />
        </div>
        <div className="content-wallet">
          <div className="content-wallet-left">
            <div className="logo-wallet">
              <img
                src="/assets/images/bridge/icn_logo_wallet_02.png"
                width={38}
                height={38}
                loading="lazy"
                alt="Your Wallet"
              />
            </div>
            <div className="address-wallet">
              <div className="address">
                <Controller
                  name="recriveAddress"
                  control={control}
                  render={({ field }: any) => (
                    <SwapInputValue>
                      <InputCommon
                        {...field}
                        disabled={!switchState}
                        // onChange={handleSendValue}
                        // onFocus={handleResetValueFrom}
                        placeHolder={`Enter ${
                          nameChainTo.nameChainTo === "BSC"
                            ? "EVM"
                            : nameChainTo.nameChainTo
                        } address`}
                        value={receiveWallet}
                        onChange={onChangeInputFrom}
                      />
                    </SwapInputValue>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="checkbox-wallet">
          <Checkbox onChange={handleCheckSelect} checked={isSelect}>
            This address is correct and not an exchange wallet. Any tokens sent
            to the wrong address will be impossible to retrieve.
          </Checkbox>
        </div>
        <ButtonCommon
          disabled={
            markedResult[markedResult.length - 1]?.address === "" ? true : false
          }
          onClick={() => setOpenModalReceipent(false)}
        >
          <p>
            {markedResult[markedResult.length - 1]?.address === ""
              ? "Receive wallet is empty"
              : "Done"}
          </p>
        </ButtonCommon>
      </ReceiveWallet>
    </ReceiveWalletContainer>
  );
};

export default ModalWallet;
