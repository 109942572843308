import styled from "styled-components";
import { ChooseTokenWrapper } from "../../../../page/Swap/ChooseToken/styled";

export const MultiChainContainer = styled.div`
  ${ChooseTokenWrapper} {
    border-bottom: 1px dashed #3a3a40;
    margin-bottom: 10px;
  }
`;
export const MultiChainFilter = styled.ul`
  margin-bottom: 20px;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  & > li {
    text-align: center;
    width: calc(100% / 3 - 30px);
    padding: 12px;
    border-radius: 8px;
    background: #43434d;
    cursor: pointer;
    transition: all 0.15s linear;
    &.active,
    &:hover {
      background-color: #fff;
      p {
        color: #007af5;
      }
    }
    p {
      font-size: 14px;
      font-weight: 500;
      transition: all 0.15s linear;
      color: #6e6e7c;
    }
  }
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    & > li {
      width: calc(33.33% - 16px);
      padding: 8px 5px;
    }
  }
`;
