import styled from "styled-components";
import { SwapInputValue } from "../../../Swap/styled";
import { InputContainer } from "../../../../components/Common/Input/styled";

export const ReceiveWalletContainer = styled.div`
  .container-receiveWallet {
    height: 55vh;
    overflow: auto;
  }
  @media screen and (max-width: 767.98px) {
    .container-receiveWallet {
      height: 45vh;
      overflow: auto;
    }
  }
`;
export const ReceiveWallet = styled.div`
  border-top: solid 1px #383838;
  padding-top: 24px;
  &:not(:last-child) {
    margin-bottom: 10px;
    .heading-wallet {
      margin-bottom: 4px;
    }
  }
  .heading-wallet {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
  }
  .title-wallet {
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    line-height: 125%;
  }
  .content-wallet {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10.12px;
    background: #28272c;
    padding: 4px 15px 4px 4px;
    margin-bottom: 12px;
  }
  .content-wallet-left {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .logo-wallet {
    display: flex;
    margin-right: 8px;
    border-radius: 50%;
  }
  .address-wallet {
    width: 100%;
  }
  .address {
    color: var(--Neutral-50, #f7f7f8);
    font-size: 14px;
    font-weight: 500;
    line-height: 125%;
    span {
      color: var(--Neutral-400, #92929e);
      font-size: 15px;
      line-height: 125%;
      margin-left: 4px;
    }
    .ant-input {
      padding: 0;
      color: var(--Neutral-400, #92929e);
      font-size: 15px;
      line-height: 125%;
      font-weight: 400;
    }
    ${SwapInputValue} {
      align-items: flex-start;
    }
    ${InputContainer} {
      width: 100%;
    }
  }
  .connect-wallet {
    color: var(--Blue--Pri-200, #b5ebff);
    font-size: 14px;
    line-height: 125%;
    letter-spacing: -0.42px;
  }
  .checkbox-wallet {
    margin-bottom: 27px;
    span {
      color: #fff;
    }
  }
`;
export const ReceiveNote = styled.p`
  font-size: 14px;
  font-family: "Rethink Sans";
  margin-top: 10px;
  color: #fff;
  span {
    font-weight: bold;
    &.currency {
      color: #0c88f5;
      font-size: 14px;
    }
    &.note {
      display: block;
      color: #fa1c1c;
      font-size: 16px;
    }
  }
`;
