import {
  BridgeNetworkList,
  BridgeSelect,
  TokenNetworkContainer,
} from "./styled";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  ChooseTokenContainer,
  ChooseTokenSearch,
  ChooseTokenWrapper,
} from "../../../Swap/ChooseToken/styled";
import { ContextProviderWrapper } from "../../../../components/Context";
import { Controller, useForm } from "react-hook-form";
import InputCommon from "../../../../components/Common/Input";
import clear from "../../../../assets/Dashboard/Common/clear.svg";
import search_icon from "../../../../assets/Dashboard/Common/search_icon.svg";
import default_token_image from "../../../../assets/Dashboard/Common/default-token-image.png";
import LoadingSpin from "../../../../components/Common/Loading";
import BigNumber from "bignumber.js";
import { toNumber } from "lodash";
import { useDispatch } from "../../../../store/store";
import { dataChainStore } from "../../../../store/features/accountSlice";
import { fetchAndStoreNativeTokens } from "../../../../store/features/tokenSlice";
import { KIBBLE_API } from "../../../../services/api";
import { useLocation, useNavigate } from "react-router-dom";

type FormValuesProps = {
  searchNetwork: string;
  searchToken: string;
  setChainList: any;
  chainList: any;
  balances: any;
  onSelectData: any;
  evmAddress: any;
  isLoadingWalletDetail: any;
  connectedWallets: any;
  setGetDecimalsToken: any;
  dataChainFrom: any;
  dataChainTo: any;
  valueInputFrom: any;
};

const TokenAndNetwork = ({
  setNameChain,
  setOpenModal,
  setDataToken,
  isFrom,
  setChainList,
  chainList,
  balances,
  onSelectData,
  evmAddress,
  isLoadingWalletDetail,
  connectedWallets,
  setGetDecimalsToken,
  dataChainFrom,
  dataChainTo,
  valueInputFrom,
}: any) => {
  const KIB_API_RANGO: any = process.env.REACT_APP_KIBBLE_RANGO;
  const { search } = useLocation();
  const { theme } = useContext(ContextProviderWrapper)!;
  const [allTokens, setAllTokens] = useState([]);
  const [tokenList, setTokenList] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [isChainSwitching, setIsChainSwitching] = useState(false);
  const [dataChain, setDataCbain] = useState<any>();
  const [isBalancesLoading, setIsBalancesLoading] = useState(true);
  const dispatch = useDispatch();
  const [currentChain, setCurrentChain] = useState("BSC");
  const [visibleItems, setVisibleItems] = useState(10);
  const [listTokenNew, setListTokenNew] = useState<any>();

  //get listTokenNew in localstorage
  useEffect(() => {
    const storedList = localStorage.getItem("listNewToken");
    if (storedList) {
      setListTokenNew(JSON.parse(storedList));
    }
  }, []);

  const formatToFourDecimals = (number: number) => {
    if (number === 0) return "0";
    if (number < 0.0001 && number > 0) return number.toFixed(8);
    return (Math.floor(number * 10000) / 10000).toFixed(4);
  };

  const handleGetChains = async () => {
    try {
      const res = await KIBBLE_API.getBlockChain(KIB_API_RANGO);
      if (res.status === 200) {
        const filteredChains = res.data.filter((item: any) =>
          [
            "eth",
            "bsc",
            "solana",
            "avax_cchain",
            "optimism",
            "arbitrum",
            "base",
          ].includes(item?.name?.toLowerCase())
        );
        // setChainList(res?.data);
        setChainList(filteredChains);
        dispatch(dataChainStore(res?.data));
        dispatch(fetchAndStoreNativeTokens(res?.data));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetAllTokens = async () => {
    setIsLoading(true);
    try {
      const res: any = await KIBBLE_API.getToken(KIB_API_RANGO);
      if (res) {
        setAllTokens(res?.data?.tokens || []);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleGetAllTokens();
    handleGetChains();
  }, []);

  useEffect(() => {
    if (!connectedWallets) {
      setIsChainSwitching(false);
      return;
    }
    setIsChainSwitching(true);
    const filteredTokens = allTokens.filter(
      (token: any) => token.blockchain === currentChain
    );
    setTokenList(filteredTokens);
    setIsChainSwitching(false);
  }, [allTokens, currentChain, connectedWallets]);

  const handleChainSwitch = (item: any) => {
    if (!connectedWallets) {
      console.warn("Wallet not connected. Cannot switch chains.");
      return;
    }
    if (item.name === currentChain) {
      console.warn("Already on the selected chain. No action taken.");
      return;
    }
    setIsChainSwitching(true);
    setCurrentChain(item.name);
    setDataCbain(item);
  };

  const defaultValues = {
    searchNetwork: "",
    searchToken: "",
  };

  const methods = useForm<FormValuesProps>({
    defaultValues,
    mode: "onChange",
  });
  const { control, watch } = methods;

  const searchNetworkText = watch("searchNetwork");
  const searchTokenText = watch("searchToken");
  //const name
  const handleGetDataTokenForm = (item: any) => {
    const token = toNumber(
      balanceFromWallet(
        String(item?.blockchain),
        String(item?.address),
        item?.decimals
      )
    ).toLocaleString();

    const price = balanceUSD(
      String(item?.blockchain),
      String(item?.address),
      item?.decimals,
      item?.usdPrice
    );

    // Send token and price data to parent component
    if (onSelectData) {
      onSelectData(token || "0", price || "0");
    }
    if (isFrom) {
      setNameChain({
        // logoChainFrom: item.blockchainImage ? item.blockchainImage : item.image,
        nameChainFrom: item.blockchain,
        logoChainFrom: dataChain?.logo,
        nameTokenFrom: item.name ? item.name : item.symbol,
        logoTokenFrom: item.image,
        priceTokenFrom: item.usdPrice,
        typeChain: dataChain?.type ? dataChain?.type : "EVM",
        nameSysbolFrom: item.symbol,
        addressFrom: item.address,
      });
      localStorage.setItem("dataFrom", JSON.stringify(item));
    } else {
      setNameChain({
        // logoChainTo: item.blockchainImage ? item.blockchainImage : item.image,
        nameChainTo: item.blockchain,
        logoChainTo: dataChain?.logo,
        nameTokenTo: item.name ? item.name : item.symbol,
        logoTokenTo: item.image,
        priceTokenTo: item.usdPrice,
        typeChain: dataChain?.type ? dataChain?.type : "EVM",
        nameSysbolTo: item.symbol,
        addressTo: item.address,
      });
      localStorage.setItem("dataTo", JSON.stringify(item));
    }
    setOpenModal(false);
    setDataToken(item);
  };

  // get amount
  useEffect(() => {
    if (evmAddress) {
      setIsBalancesLoading(true);
    } else {
      setIsBalancesLoading(false);
    }
    if (balances && balances.length > 0) {
      setIsBalancesLoading(false);
    }
  }, [balances, connectedWallets]);

  const balanceFromWallet = useMemo(() => {
    return (
      chain: string,
      address: string | null,
      decimals: number,
      symbol?: string
    ) => {
      if (!balances || !Array.isArray(balances) || balances.length === 0) {
        return "0";
      }

      const selectedChainBalances = balances.find(
        (balance: any) => balance.blockChain === chain
      );

      if (
        !selectedChainBalances ||
        !Array.isArray(selectedChainBalances.balances)
      ) {
        return "0";
      }

      const getAmountChain = selectedChainBalances.balances.find(
        (obj: any) =>
          (address === null && obj.asset?.symbol === symbol) ||
          (address && obj.asset?.address === address)
      );

      if (!getAmountChain) {
        return "0";
      }

      const balanceBignumber = new BigNumber(
        getAmountChain.amount?.amount || 0
      );
      const tokenBalance = balanceBignumber
        .dividedBy(new BigNumber(10).pow(decimals))
        .toNumber();
      return formatToFourDecimals(tokenBalance);
    };
  }, [balances]);

  // balance usd
  const balanceUSD = useMemo(() => {
    return (
      chain: string,
      address: string | null,
      decimals: number,
      price: number,
      symbol?: string
    ) => {
      if (!balances || !Array.isArray(balances) || balances.length === 0) {
        return "0";
      }
      const selectedChainBalances = balances.find(
        (balance: any) => balance.blockChain === chain
      );
      if (
        !selectedChainBalances ||
        !Array.isArray(selectedChainBalances.balances)
      ) {
        return "0";
      }
      const getAmountChain = selectedChainBalances.balances.find(
        (obj: any) =>
          (address &&
            obj.asset?.address?.toLowerCase() === address?.toLowerCase()) ||
          (!address && obj.asset?.symbol === symbol)
      );
      if (!getAmountChain) {
        return "0";
      }
      const balanceBignumber = new BigNumber(
        getAmountChain.amount?.amount || 0
      ).multipliedBy(price);
      const tokenBalanceUSD = balanceBignumber
        .dividedBy(new BigNumber(10).pow(decimals))
        .toNumber();
      return formatToFourDecimals(tokenBalanceUSD).toString();
    };
  }, [balances]);

  // merge listTokenNew and ListToken
  const mergedTokenList = useMemo(() => {
    if (!Array.isArray(listTokenNew) || !Array.isArray(tokenList)) {
      return tokenList;
    }
    const combinedList = [
      ...tokenList,
      ...listTokenNew.filter((tokenNew: any) =>
        tokenList.every((token: any) => token.address !== tokenNew.address)
      ),
    ];
    return combinedList.sort((a: any, b: any) => {
      const balanceA = Number(
        balanceFromWallet(
          currentChain,
          a.address === null ? null : a.address,
          a.decimals,
          a.symbol
        )
      );
      const balanceB = Number(
        balanceFromWallet(
          currentChain,
          b.address === null ? null : b.address,
          b.decimals,
          b.symbol
        )
      );
      const priceA = parseFloat(
        balanceUSD(
          currentChain,
          a.address === null ? null : a.address,
          a.decimals,
          a.usdPrice,
          a.symbol
        )
      );
      const priceB = parseFloat(
        balanceUSD(
          currentChain,
          b.address === null ? null : b.address,
          b.decimals,
          b.usdPrice,
          b.symbol
        )
      );
      if (priceA > 0 && priceB === 0) return -1;
      if (priceB > 0 && priceA === 0) return 1;
      if (a.address === null && b.address !== null) return -1;
      if (b.address === null && a.address !== null) return 1;
      if (balanceA > balanceB) return -1;
      if (balanceB > balanceA) return 1;
      return priceB - priceA;
    });
  }, [listTokenNew, tokenList, balanceFromWallet, balanceUSD, currentChain]);

  // filter list token
  const filteredMergedTokenList = useMemo(() => {
    return mergedTokenList?.filter(
      (item: any) =>
        item.name?.toLowerCase().includes(searchTokenText.toLowerCase()) ||
        item.symbol?.toLowerCase().includes(searchTokenText.toLowerCase()) ||
        item.address?.toLowerCase().includes(searchTokenText.toLowerCase())
    );
  }, [mergedTokenList, searchTokenText]);

  //filter list blockchain
  const filteredChainList = chainList?.filter(
    (item: any) =>
      item.name?.toLowerCase().includes(searchNetworkText.toLowerCase()) ||
      item.id?.toLowerCase().includes(searchNetworkText.toLowerCase())
  );

  //sort when have price
  // const sortedTokenList = useMemo(() => {
  //   return filteredTokenList?.sort((a: any, b: any) => {
  //     const balanceA = Number(
  //       balanceFromWallet(
  //         currentChain,
  //         a.address === null ? null : a.address,
  //         a.decimals,
  //         a.symbol
  //       )
  //     );
  //     const balanceB = Number(
  //       balanceFromWallet(
  //         currentChain,
  //         b.address === null ? null : b.address,
  //         b.decimals,
  //         b.symbol
  //       )
  //     );
  //     const priceA = parseFloat(
  //       balanceUSD(
  //         currentChain,
  //         a.address === null ? null : a.address,
  //         a.decimals,
  //         a.usdPrice,
  //         a.symbol
  //       )
  //     );
  //     const priceB = parseFloat(
  //       balanceUSD(
  //         currentChain,
  //         b.address === null ? null : b.address,
  //         b.decimals,
  //         b.usdPrice,
  //         b.symbol
  //       )
  //     );
  //     if (priceA === 0 && priceB === 0) return 0;
  //     if (priceA === 0) return 1;
  //     if (priceB === 0) return -1;
  //     if (balanceA > balanceB) return -1;
  //     if (balanceB > balanceA) return 1;
  //     return priceB - priceA;
  //   });
  // }, [filteredTokenList, balances, currentChain]);

  // handle scroll load more item
  const handleScroll = (event: React.UIEvent<HTMLUListElement, UIEvent>) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    if (scrollHeight - scrollTop <= clientHeight + 50) {
      setVisibleItems((prev) => Math.min(prev + 10, mergedTokenList.length));
    }
  };

  //get params url
  const navigate = useNavigate();
  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    // handle `dataChainFrom`
    if (dataChainFrom?.nameChainFrom && dataChainFrom?.nameSysbolFrom) {
      const fromToken = dataChainFrom.addressFrom
        ? `${dataChainFrom.nameSysbolFrom}--${dataChainFrom.addressFrom}`
        : dataChainFrom.nameSysbolFrom;
      queryParams.set("fromBlockchain", dataChainFrom.nameChainFrom);
      queryParams.set("fromToken", fromToken);
    }
    // handle `dataChainTo`
    if (dataChainTo?.nameChainTo && dataChainTo?.nameSysbolTo) {
      const toToken = dataChainTo.addressTo
        ? `${dataChainTo.nameSysbolTo}--${dataChainTo.addressTo}`
        : dataChainTo.nameSysbolTo;
      queryParams.set("toBlockchain", dataChainTo.nameChainTo);
      queryParams.set("toToken", toToken);
    }
    // handle `fromAmount`
    if (valueInputFrom > 0) {
      queryParams.set("fromAmount", valueInputFrom);
    }
    // update URL
    if (queryParams.toString()) {
      navigate(`/bridge?${queryParams.toString()}`, { replace: true });
    } else {
      navigate(`/bridge`, { replace: true });
    }
  }, [dataChainFrom, dataChainTo, valueInputFrom, search]);

  return (
    <TokenNetworkContainer>
      {/* Search chain */}
      <ChooseTokenContainer>
        <ChooseTokenWrapper className={theme}>
          <ChooseTokenSearch className={theme}>
            <Controller
              name="searchNetwork"
              control={control}
              render={({ field }: any) => (
                <InputCommon
                  {...field}
                  // disabled={loadingState ? true : false}
                  placeHolder="Search"
                  name="searchNetwork"
                  allowClear={{ clearIcon: <img src={clear} alt="icon" /> }}
                  prefix={<img src={search_icon} alt="icon" />}
                />
              )}
            />
            <p>
              <span>Network</span>
            </p>
          </ChooseTokenSearch>
          <BridgeNetworkList className={theme}>
            {filteredChainList?.map((item: any, index: number) => {
              return (
                <li
                  key={index}
                  className={item.name === currentChain ? "is-active" : ""}
                  onClick={() => handleChainSwitch(item)}
                >
                  <figure>
                    <img
                      width={24}
                      height={24}
                      src={item.logo}
                      alt="icon"
                      loading="lazy"
                    />
                  </figure>
                  <p>{item.name ? item.name : "--"}</p>
                </li>
              );
            })}
          </BridgeNetworkList>
        </ChooseTokenWrapper>
      </ChooseTokenContainer>

      {/* Search token */}
      <ChooseTokenContainer>
        <ChooseTokenWrapper className={theme}>
          <ChooseTokenSearch className={theme}>
            <Controller
              name="searchToken"
              control={control}
              render={({ field }: any) => (
                <InputCommon
                  {...field}
                  // disabled={loadingState ? true : false}
                  placeHolder="Enter name or token address"
                  name="searchToken"
                  allowClear={{ clearIcon: <img src={clear} alt="icon" /> }}
                  prefix={<img src={search_icon} alt="icon" />}
                />
              )}
            />
            <p>
              <span>Token</span>
            </p>
          </ChooseTokenSearch>
          <BridgeSelect>
            {loading ||
            isBalancesLoading ||
            isLoadingWalletDetail ||
            isChainSwitching ? (
              <LoadingSpin />
            ) : (
              <BridgeNetworkList className={theme} onScroll={handleScroll}>
                {filteredMergedTokenList
                  ?.slice(0, visibleItems)
                  .map((item: any, index) => {
                    return (
                      <li
                        key={index}
                        onClick={() => {
                          handleGetDataTokenForm(item);
                          if (isFrom) {
                            setGetDecimalsToken(item.decimals);
                          }
                        }}
                        className="item-token"
                      >
                        <div className="token-left">
                          <figure>
                            <img
                              width={24}
                              height={24}
                              src={item.image}
                              alt="icon"
                              loading="lazy"
                              onError={(e) =>
                                (e.currentTarget.src = default_token_image)
                              }
                            />
                          </figure>
                          <div className="token-name">
                            <p>{item.name ? item.name : item.symbol}</p>
                            <p className="token-sysbol">
                              {item.symbol ? item.symbol : "--"}
                            </p>
                          </div>
                        </div>
                        <div className="token-right">
                          <p className="token">
                            {balanceFromWallet(
                              currentChain,
                              item.address,
                              item.decimals,
                              item.symbol
                            )}
                          </p>
                          <p className="price">
                            $
                            {Number(
                              balanceUSD(
                                currentChain,
                                item.address === null ? null : item.address,
                                item.decimals,
                                item.usdPrice,
                                item.symbol
                              )
                            ) > 0
                              ? balanceUSD(
                                  currentChain,
                                  item.address === null ? null : item.address,
                                  item.decimals,
                                  item.usdPrice,
                                  item.symbol
                                )
                              : "0"}
                          </p>
                        </div>
                      </li>
                    );
                  })}
              </BridgeNetworkList>
            )}
          </BridgeSelect>
        </ChooseTokenWrapper>
      </ChooseTokenContainer>
    </TokenNetworkContainer>
  );
};

export default TokenAndNetwork;
