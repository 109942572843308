import { useWallet } from "@solana/wallet-adapter-react";
import { ButtonWallet, WalletConnectedTag } from "../styled";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addConnectedWallet,
  removeConnectedWallet,
} from "../../../../store/features/accountSlice";

const SolanaWalletList = ({
  searchText,
  localConnectedWallets,
  setLocalConnectedWallets,
}: any) => {
  const {
    wallets: WalletList,
    disconnect,
    select,
    publicKey,
    wallet,
  } = useWallet();
  const dispatch = useDispatch();
  const connectedWallets = useSelector(
    (state: any) => state.accountSlice.referrals
  );

  const filteredWallets = WalletList.filter(
    (wallet: any) =>
      wallet.readyState === "Installed" &&
      (!searchText ||
        wallet.adapter.name.toLowerCase().includes(searchText.toLowerCase()))
  );

  const [autoReconnectTriggered, setAutoReconnectTriggered] = useState(false);

  const saveToLocalStorage = (wallet: any) => {
    const existingWallets = JSON.parse(
      localStorage.getItem("lastConnectedWallet") || "[]"
    );
    const updatedWallets = [
      ...existingWallets.filter((w: any) => w.name !== wallet.name),
      wallet,
    ];
    localStorage.setItem("lastConnectedWallet", JSON.stringify(updatedWallets));
  };

  const removeFromLocalStorage = (walletName: string) => {
    const existingWallets = JSON.parse(
      localStorage.getItem("lastConnectedWallet") || "[]"
    );
    const updatedWallets = existingWallets.filter(
      (wallet: any) => wallet.name !== walletName
    );
    localStorage.setItem("lastConnectedWallet", JSON.stringify(updatedWallets));
  };

  const handleConnect = async (walletName: any) => {
    try {
      await select(walletName);
      if (publicKey && wallet) {
        const walletData = {
          img: `https://raw.githubusercontent.com/rango-exchange/assets/main/wallets/${wallet.adapter.name.toLowerCase()}/icon.svg`,
          name: wallet.adapter.name,
          address: publicKey.toString(),
          type: "SOLANA",
        };

        setLocalConnectedWallets((prev: any) => ({
          ...prev,
          [wallet.adapter.name]: walletData,
        }));

        dispatch(addConnectedWallet(walletData));
        saveToLocalStorage(walletData);
      }
    } catch (error) {
      console.error(`Failed to connect to wallet: ${walletName}`, error);
    }
  };

  const handleDisconnect = async (walletName: string) => {
    try {
      await disconnect();

      setLocalConnectedWallets((prev: any) => {
        const { [walletName]: _, ...remainingWallets } = prev;
        return remainingWallets;
      });

      const walletToRemove = connectedWallets.find(
        (wallet: any) => wallet.name === walletName
      );

      if (walletToRemove) {
        dispatch(removeConnectedWallet(walletToRemove.address));
      }

      removeFromLocalStorage(walletName);
    } catch (error) {
      console.error(`Failed to disconnect wallet: ${walletName}`, error);
    }
  };

  useEffect(() => {
    if (publicKey && wallet) {
      const walletData = {
        img: `https://raw.githubusercontent.com/rango-exchange/assets/main/wallets/${
          wallet.adapter.name.toLowerCase() &&
          wallet.adapter.name !== "MetaMask"
            ? wallet.adapter.name.toLowerCase()
            : wallet.adapter.name === "MetaMask"
            ? "solflare-snap"
            : ""
        }/icon.svg`,
        name:
          wallet.adapter.name === "MetaMask"
            ? "solflare-snap"
            : wallet.adapter.name,
        address: publicKey.toString(),
        type: "Solana",
      };

      setLocalConnectedWallets((prev: any) => ({
        ...prev,
        [wallet.adapter.name]: walletData,
      }));

      saveToLocalStorage(walletData);

      dispatch(addConnectedWallet(walletData));
    }
  }, [publicKey, wallet, dispatch]);

  useEffect(() => {
    if (!autoReconnectTriggered) {
      const savedWallets = JSON.parse(
        localStorage.getItem("lastConnectedWallet") || "[]"
      );
      if (savedWallets.length > 0) {
        savedWallets.forEach((savedWallet: any) => {
          if (savedWallet.type === "Solana") {
            setLocalConnectedWallets((prev: any) => ({
              ...prev,
              [savedWallet.name]: savedWallet,
            }));
            dispatch(addConnectedWallet(savedWallet));
            handleConnect(savedWallet.name);
          }
        });
      }
      setAutoReconnectTriggered(true);
    }
  }, [autoReconnectTriggered]);

  return (
    <>
      {filteredWallets.length > 0 ? (
        filteredWallets.map((wallet) => {
          const isConnected = !!localConnectedWallets[wallet.adapter.name];
          return (
            <ButtonWallet
              key={wallet.adapter.name}
              onClick={() => {
                if (isConnected) {
                  handleDisconnect(wallet.adapter.name);
                } else {
                  handleConnect(wallet.adapter.name);
                }
              }}
            >
              <figure>
                <img
                  src={`https://raw.githubusercontent.com/rango-exchange/assets/main/wallets/${
                    wallet.adapter.name.toLowerCase() &&
                    wallet.adapter.name !== "MetaMask"
                      ? wallet.adapter.name.toLowerCase()
                      : wallet.adapter.name === "MetaMask"
                      ? "solflare-snap"
                      : ""
                  }/icon.svg`}
                  alt="icon"
                />
              </figure>
              <p>
                {wallet.adapter.name === "MetaMask"
                  ? "Solana-snap"
                  : wallet.adapter.name}
              </p>
              <WalletConnectedTag
                className={isConnected ? "connected" : "normal"}
              >
                {isConnected ? "Connected" : "Not connect"}
              </WalletConnectedTag>
            </ButtonWallet>
          );
        })
      ) : (
        <></>
      )}
    </>
  );
};

export default SolanaWalletList;
