import React, { useEffect, useState } from "react";
import { ButtonWallet, WalletConnectedTag } from "../styled";
import {
  addConnectedWallet,
  removeConnectedWallet,
} from "../../../../store/features/accountSlice";
import { useDispatch, useSelector } from "react-redux";

interface TronInterface {
  setLocalConnectedWallets: any;
  localConnectedWallets: any;
}
const ConnectTronLink = ({
  setLocalConnectedWallets,
  localConnectedWallets,
}: TronInterface) => {
  const dispatch = useDispatch();
  const [isConnected, setIsConnected] = useState(false);
  const [walletAddress, setWalletAddress] = useState<string | null>(null);

  const checkTronLinkReady = async (): Promise<boolean> => {
    if (
      typeof window !== "undefined" &&
      window.tronWeb &&
      window.tronWeb.ready
    ) {
      return true;
    }
    return false;
  };

  const getWalletAddress = (): string | null => {
    const tronWeb = window.tronWeb;
    return tronWeb?.defaultAddress?.base58 || null;
  };

  const connectTronLink = async () => {
    try {
      const isReady = await checkTronLinkReady();
      if (!isReady) {
        throw new Error(
          "TronLink is not ready. Please unlock your wallet and select an account."
        );
      }
      const tronWeb = window.tronWeb;
      let address = getWalletAddress();
      const MAX_ADDRESS_RETRIES = 10;
      let retries = 0;

      while (!address && retries < MAX_ADDRESS_RETRIES) {
        await new Promise((resolve) => setTimeout(resolve, 500));
        address = getWalletAddress();
        retries++;
      }

      if (address) {
        setWalletAddress(address);
        setIsConnected(true);
        localStorage.setItem("tronWalletConnected", "true"); // Save connection status
      } else {
        throw new Error(
          "Address not found. Please unlock your wallet and select an account."
        );
      }
    } catch (error) {
      console.error("Unable to connect to TronLink:", error);
      setIsConnected(false);
      setWalletAddress(null);
    }
  };

  const handleDisconnect = () => {
    setWalletAddress(null);
    setIsConnected(false);
    localStorage.removeItem("tronWalletConnected"); // Clear connection status
    dispatch(removeConnectedWallet(walletAddress));
    setLocalConnectedWallets((prev: any) => {
      const { TronLink, ...remainingWallets } = prev;
      return remainingWallets;
    });
  };

  useEffect(() => {
    const updateWalletWithOsmosis = async () => {
      if (walletAddress) {
        const walletData = {
          img: `https://raw.githubusercontent.com/rango-exchange/assets/main/wallets/tronlink/icon.svg`,
          name: "TronLink",
          address: walletAddress,
          type: "TRON",
        };
        setLocalConnectedWallets((prev: any) => ({
          ...prev,
          test: walletData,
        }));
        dispatch(addConnectedWallet(walletData));
      }
    };
    if (walletAddress) {
      updateWalletWithOsmosis();
    }
  }, [walletAddress]);

  useEffect(() => {
    const initializeConnection = async () => {
      const storedConnection = localStorage.getItem("tronWalletConnected");
      if (storedConnection === "true") {
        const isReady = await checkTronLinkReady();
        if (isReady) {
          const address = getWalletAddress();
          if (address) {
            setWalletAddress(address);
            setIsConnected(true);
          } else {
            localStorage.removeItem("tronWalletConnected"); // Clear if no address
          }
        }
      }
    };

    initializeConnection();
  }, []);

  return (
    <>
      <ButtonWallet
        onClick={() => {
          if (isConnected) {
            handleDisconnect();
          } else {
            connectTronLink();
          }
        }}
      >
        <WalletConnectedTag className={isConnected ? "connected" : "normal"}>
          <figure>
            <img
              src={
                "https://raw.githubusercontent.com/rango-exchange/assets/main/wallets/tronlink/icon.svg"
              }
              alt="icon"
            />
          </figure>
          <p>TronLink</p>
          <WalletConnectedTag className={isConnected ? "connected" : "normal"}>
            {isConnected ? "Connected" : "Not connect"}
          </WalletConnectedTag>
        </WalletConnectedTag>
      </ButtonWallet>
    </>
  );
};

export default ConnectTronLink;
